import React, { Component } from 'react'
import LoadingDefault from '../../Elements/Loading/LoadingDefault'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'react-bootstrap'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import VerzoekToewijzingForm from './VerzoekToewijzingForm'

class VerzoekToewijzingen extends Component {
    constructor(props) {
        super(props)

        this.state = {
            errorData: null,
            loading: false,
            dossierId: null,
            items: [],
            pager: {},
        }
    }

    addClient(dossierId) {
        // /module/ijwiwmo/client/add
        const api = this.props.kedo.api()
        this.setState({ loading: true })
        api.post('/module/ijwiwmo/client/add/' + this.props.dossierId)
            .then((response) => {
                window.location.reload()
            })
            .catch((err) => {
                alert('Fout bij aanmaken cliënt')
            })
    }

    fetchData() {
        if (this.state.loading) {
            return
        }
        const api = this.props.kedo.api()
        this.setState({ loading: true })
        api.get('/module/ijwiwmo/vot/' + this.props.dossierId)
            .then((response) => {
                this.setState({
                    loading: false,
                    errorData: null,
                    items: response.data.results,
                    pager: response.data.pager,
                    dossierId: this.props.dossierId,
                })
            })
            .catch((err) => {
                if (err.response.status === 400) {
                    this.setState({
                        loading: false,
                        errorData: err.response.data,
                    })
                } else {
                    this.setState({
                        errorData: null,
                        loading: false,
                    })
                }
            })
    }

    componentDidMount() {
        this.fetchData()
    }

    render() {
        const kedo = this.props.kedo

        if (this.state.loading) {
            return <LoadingDefault />
        }

        if (this.state.errorData) {
            if (
                this.state.errorData &&
                this.state.errorData.errors &&
                this.state.errorData.errors.hasBirthDate &&
                this.state.errorData.errors.hasLastname &&
                this.state.errorData.errors.hasGender
            ) {
                return (
                    <div className="alert alert-info field-grid-wrapper jwwmo-add-person">
                        <p>
                            De desbetreffende persoon is niet bekend voor het
                            berichtenverkeer, geef hier onder akkoord om deze
                            persoon door te sturen.
                        </p>
                        <btn
                            className="btn btn-primary"
                            onClick={() => this.addClient()}
                        >
                            {kedo.t('Create')}
                        </btn>
                        <a className="btn btn-secondary" href="">
                            {kedo.t('Cancel')}
                        </a>
                    </div>
                )
            }

            return (
                <div className="alert alert-info" role="alert">
                    Er missen gegevens (
                    {this.state.errorData.errors &&
                    !this.state.errorData.errors.hasBirthDate
                        ? 'geboortedatum '
                        : ''}
                    {this.state.errorData.errors &&
                    !this.state.errorData.errors.hasLastname
                        ? 'achternaam '
                        : ''}
                    {this.state.errorData.errors &&
                    !this.state.errorData.errors.hasGender
                        ? 'geslacht '
                        : ''}
                    ) waardoor er geen berichten voor het berichtenverkeer
                    opgehaald kunnen worden. Vul de ontbrekende gegevens in.
                </div>
            )
        }

        return (
            <div>
                {this.state.add ? (
                    <VerzoekToewijzingForm
                        kedo={kedo}
                        onClose={(refresh) =>
                            this.setState({ add: null }, () => {
                                if (refresh === true) {
                                    this.fetchData()
                                }
                            })
                        }
                        dossierId={this.props.dossierId}
                    />
                ) : null}
                <Button
                    className={'float-right'}
                    onClick={() => this.setState({ add: true })}
                >
                    <FontAwesomeIcon icon={faPlus} />
                </Button>
                <table className={'table table-striped'}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Gemeente</th>
                            <th>Ingangsdatum</th>
                            <th>Product</th>
                            <th>Eenheid</th>
                            <th>Volume</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.items.map((item) => (
                            <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.gemeentenaam}</td>
                                <td>
                                    {kedo
                                        .utils()
                                        .dateFormat(
                                            item.toewijzing_ingangsdatum,
                                            'YYYY-MM-DD'
                                        )}
                                </td>
                                <td>
                                    {item.prod_cat_naam} {item.product_code}
                                </td>
                                <td>{item.eenheid_naam}</td>
                                <td>{item.volume}</td>
                                <td>
                                    {item.processed &&
                                    item.processed.length > 0 ? (
                                        <div
                                            className="btn-toolbar"
                                            role="toolbar"
                                        >
                                            <div
                                                className="btn-group"
                                                role="group"
                                            >
                                                {item.statussen.map(
                                                    (status) => {
                                                        let faulty = false
                                                        if (
                                                            status.retour_codes &&
                                                            status.retour_codes
                                                                .length > 0
                                                        ) {
                                                            status.retour_codes.map(
                                                                (retour) => {
                                                                    if (
                                                                        retour.faulty ==
                                                                        true
                                                                    ) {
                                                                        faulty = true
                                                                    }
                                                                }
                                                            )

                                                            return (
                                                                <>
                                                                    {faulty ===
                                                                    true ? (
                                                                        <button
                                                                            disabled="disabled"
                                                                            type="button"
                                                                            className="btn btn-xs btn-warning"
                                                                        >
                                                                            <i className="fa fa-minus"></i>
                                                                        </button>
                                                                    ) : (
                                                                        <button
                                                                            disabled="disabled"
                                                                            type="button"
                                                                            className="btn btn-xs btn-success"
                                                                        >
                                                                            <i className="fa fa-check"></i>
                                                                        </button>
                                                                    )}
                                                                    <div>
                                                                        {
                                                                            status.aangemaakt_op
                                                                        }{' '}
                                                                        {
                                                                            status.status
                                                                        }
                                                                        <br />
                                                                        {status.retour_codes.map(
                                                                            (
                                                                                retour
                                                                            ) => (
                                                                                <span>
                                                                                    {
                                                                                        retour.code
                                                                                    }

                                                                                    :{' '}
                                                                                    {
                                                                                        retour.msg
                                                                                    }{' '}
                                                                                    <br />
                                                                                </span>
                                                                            )
                                                                        )}
                                                                        <span className="fa fa-info"></span>
                                                                    </div>
                                                                </>
                                                            )
                                                        } else {
                                                            return (
                                                                <button
                                                                    disabled="disabled"
                                                                    type="button"
                                                                    className="btn btn-xs btn-success"
                                                                >
                                                                    <i className="fa fa-check"></i>
                                                                </button>
                                                            )
                                                        }
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    ) : item.state && item.state === 3 ? (
                                        <div>
                                            In wachtrij{' '}
                                            <span className="fa fa-clock-o"></span>
                                        </div>
                                    ) : null}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default VerzoekToewijzingen
